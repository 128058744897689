/* App.css */
@font-face {
  font-family: 'DevLys 10';
  src: url('./fonts/DevLys_010.ttf') format('truetype')
}

@font-face {
  font-family: 'Kruti Dev 010';
  src: url('./fonts/KRDEV010.ttf') format('truetype');
}

@font-face {
  font-family: 'Mangal';
  src: url('./fonts/mangal.ttf') format('truetype');
}

div.ck.ck-toolbar.ck-toolbar_grouping.ck-reset_all.ck-rounded-corners {
  width: 100%;
}

.ck {
  width: 100%;
  height: 400px;
  background: white;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.controls {
  margin-bottom: 20px;
}

.record-button, .stop-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.record-button {
  background-color: #4CAF50;
}

.record-button:hover {
  background-color: #45a049;
}

.stop-button {
  background-color: #f44336;
}

.stop-button:hover {
  background-color: #d73829;
}

.save-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button {
  background-color: #3399CC;
}

.save-button:hover {
  background-color: #2d89b7;
}

.status {
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 60px;
}

.recognizing {
  padding: 10px 20px;
  border: none;
  background: linear-gradient(to bottom, #f0f0f0 0%, #e0e0e0 100%);
  border-radius: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif; /* Change the font family as needed */
  color: #333;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: all 1s;

}

ol {
  list-style-type: decimal; /* Default style for ordered lists */
}


@media (max-width: 600px) {
  .table-class th, .table-class td {
    padding: 1px; /* Reduce padding */
    font-size: 10px; /* Reduce font size */
  }
  .flex {
    flex-direction: column;
  }
  .record-button, .save-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
  